<template>
  <v-row>
    <v-col cols="12">
      <div style="position: relative;">
        <v-pagination
            v-model="pagination.page"
            :length="pagination.length"
            @input="pageChange"
            :total-visible="10"
            circle
        />
        <div style="position: absolute; top: 0; left: 0;">
          {{ pagination.total_count }}
          <span v-if="pagination.total_count >= 2000">件以上</span>
          <span v-else>件</span>
        </div>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="table-tochis">
        <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="search_options"
            :server-items-length="pagination.total_count"
            :hide-default-footer="true"
            :multi-sort="false"
            :loading="loading"
        >
          <template v-slot:item.kukaku_image_url="{item}">
            <div style="text-align: center;">
              <a :href="item.link_url" target="_blank">
                <img v-if="!item.kukaku_image_url" src="data:image/gif;base64,R0lGODlhAQABAGAAACH5BAEKAP8ALAAAAAABAAEAAAgEAP8FBAA7" class="thumb-image" />
                <v-tooltip top v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <img :src="item.kukaku_image_url" style="max-height: 60px; max-width: 60px; clear: left;" v-bind="attrs" v-on="on" />
                  </template>
                  <img :src="item.kukaku_image_url" style="max-height: 400px; max-width: 400px; clear: left;" />
                </v-tooltip>
              </a>
            </div>
          </template>
          <template v-slot:item.kakaku="{item}">
            <div v-if="!!item.kakaku">
              <div v-if="item.tochi_histories.length == 0" class="text-right">{{Number(item.kakaku).toLocaleString()}}</div>
              <div v-if="item.tochi_histories.length > 0" class="text-right">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs, }">
                    <span v-on="on" v-bind="attrs">
                      <u>{{Number(item.kakaku).toLocaleString()}}</u>
                    </span>
                  </template>
                  <div>価格変動履歴</div>
                  <ul v-if="item.tochi_histories.length > 0">
                    <li v-for="(history, id) in item.tochi_histories" :key="id">
                      {{ history.created_at }} .. {{ Number(history.kakaku).toLocaleString() }}
                    </li>
                  </ul>
                </v-tooltip> 
             </div>
            </div>
          </template>
          <template v-slot:item.youto_chiiki="{item}">
            {{ item.youto_chiiki }}
          </template>
          <template v-slot:item.isshu_tanka="{item}">
            <div v-if="!!item.isshu_tanka" class="text-right">{{item.isshu_tanka}}</div>
          </template>
          <template v-slot:item.menseki="{item}">
            <div v-if="!!item.menseki" class="text-right menseki">{{item.menseki}}</div>
          </template>
          <template v-slot:item.kenpei="{item}">
            <div v-if="!!item.kenpei" class="text-right">
              <span v-if="!!item.kenpei">{{item.kenpei}}</span>
              /
              <span v-if="!!item.yoseki">{{item.yoseki}}</span>
            </div>
          </template>
          <template v-slot:item.cached_at="{item}">
            <v-chip v-if="!item.end_at" class="ma-1" x-small color="primary">
              掲載中
            </v-chip>
            <v-chip v-if="!!item.end_at" class="ma-1" x-small color="default">
              掲載終了
            </v-chip>
            <div>{{item.cached_at}}〜</div>
            <div v-if="!!item.end_at">{{item.end_at}}</div>
          </template>
          <template v-slot:item.shido_futan="{item}">
            <small v-html="item.shido_futan"></small>
          </template>
          <template v-slot:item.kotsu="{item}">
            <ul v-for="(kotsu, index) in item.kotsu" :key="index">
              <li><small v-html="kotsu" /></li>
            </ul>
          </template>
          <template v-slot:item.address_str="{item}">
            {{item.city}}{{item.address_str}}
          </template>
          <template v-slot:item.tenpo_id="{item}">
            <div v-if="item.tenpo">
              <div>{{item.tenpo.name}}</div>
              <div v-if="item.tenpo.address"><small>{{item.tenpo.address}}</small></div>
              <div v-if="item.tenpo.tel"><small>TEL:{{item.tenpo.tel}}</small></div>
            </div>
          </template>
          <template v-slot:item.platform_id="{item}">
            <a :href="item.link_url" target="_blank">
              <div>{{item.platform_id}}</div>
            </a>
          </template>
        </v-data-table>
      </div>
    </v-col>
    <v-col cols="12">
        <v-pagination
            v-model="pagination.page"
            :length="pagination.length"
            @input="pageChange"
            :total-visible="10"
            circle
        />
    </v-col>
  </v-row>
</template>

<script>

 export default {
   name: 'TochiList',
   props: {
     store: null,
     items: [],
     loading: null,
     pagination: {
       page: 0,
       length: 0,
       total_count: 0,
     },
   },
   watch: {
     search_options: {
       handler () {
         this.$props.store.commit('change_sort', { key: this.search_options.sortBy[0], desc: this.search_options.sortDesc[0], });
       },
       deep: true,
     },
   },
   data() {
     return {
       headers: [
         { text: '区画図', value: 'kukaku_image_url', sortable: false, },
         { text: '掲載開始', value: 'cached_at', },
         { text: '住所', value: 'address_str', sortable: false, },
         { text: '一種(万)', value: 'isshu_tanka', sortable: true, },
         { text: '価格(万)', value: 'kakaku', sortable: true, },
         { text: '面積', value: 'menseki', class: 'sup2', sortable: true, },
         { text: '建蔽/容積 (%)', value: 'kenpei', sortable: false, },
         { text: '用途', value: 'youto_chiiki', sortable: true,  }, 
         { text: '交通', value: 'kotsu', sortable: false, },
         { text: '様態', value: 'torihiki_taiyou', sortable: true, },
         { text: '会社', value: 'tenpo_id', sortable: true, },
         { text: '私道負担', value: 'shido_futan', sortable: false, },
         { text: 'ソース', value: 'platform_id', sortable: false, },
         { text: 'ID', value: 'id', sortable: true, },
       ],
       search_options: {
         itemsPerPage: 100,
       },
     };
   },
   methods: {
     pageChange(number) {
       this.$emit('page-change', { page: number });
     },
   },
 }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 .text-start {
    white-space: nowrap;
 }
 .text-light {
     text-align: center;
 }
 .per:after {
     content: '%';
 }
 .table-tochis ul {
     list-style: none;
     padding: 0;
     margin: 0;
 }
 .thumb-image {
     height: 60px; width: 60px; background-color: #fff;
 }
</style>

<style>
 .table-tochis table tr {
 }
 .table-tochis table td {
     padding: 1em !important;
 }
 .table-tochis .text-start {
    white-space: nowrap;
 }
 .table-tochis .sup2 > span:after {
     content: '(m\0B2)';
 }
 .table-tochis table td:not(.v-data-table__mobile-row) {
     max-width: 280px;
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
 }
 .table-tochis table { border: 1px solid #dddddd; }
 .table-tochis table thead th { background-color: #eee !important;  }
 .table-tochis table th + th { border-left: 1px solid #dddddd; }
 .table-tochis table td + td { border-left: 1px solid #dddddd; }
</style>
