import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import _ from 'lodash'

Vue.use(Vuex);

const STATE_PROTOTYPE = {
  sort: {
    key: null,
    desc: false,
  },
  search: {
    selected_cities: [],
    selected_platform_ids: [],
    has_history: false,
    visible: 1,
    cached_at: {
      enabled: false,
      val: 7,
    },
    isshu_tanka: {
      enabled: false,
      range: [ 0, 100, ],
    },
    kakaku: {
      enabled: false,
      range: [ 1000, 7000, ],
    },
    menseki: {
      enabled: false,
      range: [ 0, 100, ],
    },
  },
};

export default new Vuex.Store({
  state:  {
    prefecture_id: null,
    prefectures: {},
  },
  getters: {
    getPrefectureId: (state) => {
      return state.prefecture_id;
    },
    getState: (state) => {
      if (!state.prefectures[state.prefecture_id]) {
        state.prefectures[state.prefecture_id] = _.cloneDeep(STATE_PROTOTYPE);
      }

      return state.prefectures[state.prefecture_id];
    },
  },
  mutations: {
    change_prefecture_id(state, payload) {
      state.prefecture_id = parseInt(payload);
      if (!state.prefectures[state.prefecture_id]) {
        state.prefectures[state.prefecture_id] = _.cloneDeep(STATE_PROTOTYPE);
      }
    },
    change_selected_cities(state, payload) {
      state.prefectures[state.prefecture_id].search.selected_cities = payload.selected_cities;
    },
    change_selected_platform_ids(state, payload) {
      state.prefectures[state.prefecture_id].search.selected_platform_ids = payload.selected_platform_ids;
    },
    change_has_history(state, payload) {
      state.prefectures[state.prefecture_id].search.has_history = payload.has_history;
    },
    change_visible(state, payload) {
      state.prefectures[state.prefecture_id].search.visible = payload.visible;
    },
    change_cached_at_enabled(state, payload) {
      state.prefectures[state.prefecture_id].search.cached_at.enabled = Boolean(payload.enabled);
    },
    change_cached_at(state, payload) {
      state.prefectures[state.prefecture_id].search.cached_at.val = payload.cached_at;
    },
    change_isshu_tanka_enabled(state, payload) {
      state.prefectures[state.prefecture_id].search.isshu_tanka.enabled = Boolean(payload.enabled);
    },
    change_isshu_tanka(state, payload) {
      state.prefectures[state.prefecture_id].search.isshu_tanka.range = [ payload.min, payload.max, ];
    },
    change_kakaku_enabled(state, payload) {
      state.prefectures[state.prefecture_id].search.kakaku.enabled = Boolean(payload.enabled);
    },
    change_kakaku(state, payload) {
      state.prefectures[state.prefecture_id].search.kakaku.range = [ payload.min, payload.max, ];
    },
    change_menseki_enabled(state, payload) {
      state.prefectures[state.prefecture_id].search.menseki.enabled = Boolean(payload.enabled);
    },
    change_menseki(state, payload) {
       state.prefectures[state.prefecture_id].search.menseki.range = [ payload.min, payload.max ];
    },
    change_sort(state, payload) {
      state.prefectures[state.prefecture_id].sort.key = payload.key;
      state.prefectures[state.prefecture_id].sort.desc = Boolean(payload.desc);
    },
  },
  plugins: [
    createPersistedState({ key: 'TochiLatestSearchForm', })
  ],
});
