<template>
  <div class="tochi-search-form">
    <v-row no-gutters>
      <v-col v-if="prefectures">
        <v-tabs>
          <v-tab v-for="_prefecture in prefectures" :key="_prefecture.id" :to="`/prefecture/${_prefecture.id}`">
            {{ _prefecture.name }}
          </v-tab>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text class="align-self-center mr-4" v-bind="attrs" v-on="on">
                保存条件
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>

            <v-list class="white--text lighten-3">
              <v-list-item v-for="item in [1,2,3,4,5]" :key="item" link>
                <v-list-item-title>
                  <router-link :to="`/alert/${item}`">{{`保存条件${item}`}}</router-link>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" lg="8" v-if="prefecture" class="select_city_group">
        <v-combobox
            v-model="selected_cities"
            :items="getCityGroup(prefecture.city_groups)"
            :label="prefecture.name"
            @change="cityCheckChanged"
            multiple
            outlined
            dense
            class="pa-0 ma-0"
        />
      </v-col>
      <v-col cols="12" md="4" lg="4" class="select_city_group">
        <v-combobox
            v-model="selected_platform_ids"
            @change="platformIdCheckChanged"
            :items="getPlatforms(platforms)"
            label="情報ソース"
            multiple
            outlined
            dense
            class="pa-0 ma-0"
        />
      </v-col>
    </v-row>
    <v-row align="center" class="py-0 my-0">
      <v-col cols="12" md="3" lg="3">
        <v-checkbox v-model="cached_at_slider.enabled" hide-details label="掲載開始日(日前以内)" class="shrink mr-2 mt-0"
                    @change="changeCachedAtEnabled" />

        <v-slider v-model="cached_at_slider.val"
                  :disabled="!cached_at_slider.enabled"
                  :max="cached_at_slider.max"
                  :min="cached_at_slider.min"
                  :step="1"
                  @change="changeCachedAt"
                  :thumb-size="24"
                  thumb-label="always"
                  style="margin-top: 24px"
        >
        </v-slider>
      </v-col>
      <v-col cols="12" md="3" lg="3">
        <v-checkbox v-model="isshu_tanka_slider.enabled" hide-details label="1種(万円)" class="shrink mr-2 mt-0" @change="changeIsshuTankaEnabled" />
        <v-range-slider v-model="isshu_tanka_slider.range"
                        :disabled="!isshu_tanka_slider.enabled"
                        :max="isshu_tanka_slider.max"
                        :min="isshu_tanka_slider.min"
                        :step="5"
                        hide-details
                        @change="changeIsshuTanka"
                        class="align-center">
          <template v-slot:prepend>
            <u>{{isshu_tanka_slider.range[0]}}</u>
          </template>
          <template v-slot:append>
            <u>{{isshu_tanka_slider.range[1]}}</u>
          </template>
        </v-range-slider>
      </v-col>
      <v-col cols="12" md="3" lg="3">
        <v-checkbox v-model="kakaku_slider.enabled" hide-details label="価格(万円)" class="shrink mr-2 mt-0" @change="changeKakakuEnabled" />
        <v-range-slider v-model="kakaku_slider.range"
                        :disabled="!kakaku_slider.enabled"
                        :min="kakaku_slider.min"
                        :max="kakaku_slider.max"
                        :step="100"
                        hide-details
                        @change="changeKakaku"
                        class="align-center">
          <template v-slot:prepend>
            <u>{{kakaku_slider.range[0]}}</u>
          </template>
          <template v-slot:append>
            <u>{{kakaku_slider.range[1]}}</u>
          </template>
        </v-range-slider>
      </v-col>
      <v-col cols="12" md="3" lg="3">
        <v-checkbox v-model="menseki_slider.enabled" hide-details label="面積(m2)" class="shrink mr-2 mt-0" @change="changeMensekiEnabled" />
        <v-range-slider v-model="menseki_slider.range"
                        :disabled="!menseki_slider.enabled"
                        :min="menseki_slider.min"
                        :max="menseki_slider.max"
                        :step="5"
                        hide-details
                        @change="changeMenseki"
                        class="align-center">
          <template v-slot:prepend>
            <u>{{menseki_slider.range[0]}}</u>
          </template>
          <template v-slot:append>
            <u>{{menseki_slider.range[1]}}</u>
          </template>
        </v-range-slider>
      </v-col>
    </v-row>
    <v-row class="py-0 my-0">
      <v-col cols="6" md="2" lg="2">
        <v-switch v-model="has_history" label="価格改定あり" @change="changeHasHistory"></v-switch>
      </v-col>
      <v-col cols="6" md="3" lg="3">
        <v-radio-group row v-model="visible" @change="changeVisible">
          <v-radio label="掲載中のみ" :value="1" hide-details />
          <v-radio label="掲載終了のみ" :value="2" hide-details />
        </v-radio-group>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
   name: 'TochiSearchForm',
   components: {
   },
   props: [
     'store',
   ],
   data() {
     return {
       platforms: [
         { id: 'reins', name: 'Reins(都心のみ)', },
         { id: 'suumo', name: 'Suumo', },
         { id: 'athome', name: 'Athome', },
       ],
       prefecture: null,
       prefectures: null,
       selected_cities: [],
       selected_platform_ids: [],
       has_history: false,
       visible: 1,
       cached_at_slider: {
         enabled: false,
         min: 0,
         max: 14,
         val: 7,
       },
       isshu_tanka_slider: {
         enabled: false,
         min: 0,
         max: 300,
         range: [ 20, 100 ],
       },
       kakaku_slider: {
         enabled: false,
         min: 0,
         max: 30000,
         range: [ 1000, 7000 ],
       },
       menseki_slider: {
         enabled: false,
         min: 0,
         max: 400,
         range: [ 0, 100, ],
       },
     };
   },
   watch: {
     '$props.store.state.prefecture_id': {
       handler() {
         this.realod();
       },
     },
   },
   created() {
     this.realod();
   },
   methods: {
     realod() {
       const state = this.$props.store.getters.getState;
       
       this.has_history = state.search.has_history;
       this.visible = state.search.visible || 1;

       this.cached_at_slider.enabled = state.search.cached_at.enabled || false;
       this.cached_at_slider.val = state.search.cached_at.val;

       this.isshu_tanka_slider.enabled = state.search.isshu_tanka.enabled || false;
       this.isshu_tanka_slider.range = state.search.isshu_tanka.range;

       this.kakaku_slider.enabled = state.search.kakaku.enabled || false;
       this.kakaku_slider.range = state.search.kakaku.range;

       this.menseki_slider.enabled = state.search.menseki.enabled || false;
       this.menseki_slider.range = state.search.menseki.range;

       this.selected_cities = state.search.selected_cities;
       this.selected_platform_ids = state.search.selected_platform_ids;

       this.axios.get('/v2/master/prefecture')
         .then(response => {
           this.prefectures = response.data.prefectures;
           this.prefecture = this.prefectures.find(prefecture => prefecture.id === parseInt(this.$props.store.getters.getPrefectureId));
         })
         .catch(e => {
           console.log(e.response);
         });
     },
     getCityGroup(city_groups) {
       const items = [];

       city_groups.forEach(city_group => {
         items.push({ divider: true, header: city_group.name, });

         city_group.cities.forEach(city => {
           items.push({ text: `${city.name} (${city.enable_count})`, value: city.id, });
         });
       });

       return items;
     },
     getPlatforms(platforms) {
       const items = [];

       platforms.forEach(platform => {
         items.push({ text: `${platform.name}`, value: platform.id, });
       });

       return items;
     },
     changeHasHistory(has_history) {
       this.has_history = has_history;
       this.$props.store.commit('change_has_history', { has_history, });
     },
     changeVisible(visible) {
       this.$props.store.commit('change_visible', { visible, });
     },
     changeCachedAtEnabled(enabled) {
       this.$props.store.commit('change_cached_at_enabled', { enabled, });
     },
     changeCachedAt(val) {
       this.$props.store.commit('change_cached_at', { cached_at: val, });
     },
     changeIsshuTankaEnabled(enabled) {
       this.$props.store.commit('change_isshu_tanka_enabled', { enabled, });
     },
     changeIsshuTanka(min_max) {
       const [ min, max ] = min_max;
       this.$props.store.commit('change_isshu_tanka', { min, max, });
     },
     changeKakakuEnabled(enabled) {
       this.$props.store.commit('change_kakaku_enabled', { enabled, });
     },
     changeKakaku(min_max) {
       const [ min, max ] = min_max;
       this.$props.store.commit('change_kakaku', { min, max, });
     },
     changeMensekiEnabled(enabled) {
       this.$props.store.commit('change_menseki_enabled', { enabled, });
     },
     changeMenseki(min_max) {
       const [ min, max ] = min_max;
       this.$props.store.commit('change_menseki', { min, max, });
     },
     cityCheckChanged(selected_cities) {
       this.$props.store.commit('change_selected_cities', { selected_cities, });
     },
     platformIdCheckChanged(selected_platform_ids) {
       this.$props.store.commit('change_selected_platform_ids', { selected_platform_ids, });
     },
   },
 };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 ul.prefectures {
     margin: 0;
     padding: 0;
 }
 ul.prefectures > li {
     margin: 0 8px;
     padding: 0;
     display: inline;
 }
</style>

<style>
 .select_city_group .v-text-field__details {
     display: none;
 }
 .tochi-search-form div[class^="col-"] {
     padding-top: 0 !important;
     padding-bottom: 0 !important;
 }
</style>
