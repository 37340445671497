<template>
  <v-container>
    <v-row>
      <v-col>
        <ul>
          <li v-for="prefecture in prefectures" :key="prefecture.id">
            <router-link :to="`/prefecture/${prefecture.id}`">
              <h2>{{ prefecture.name }}</h2>
            </router-link>
            <ul>
              <li v-for="city_group in prefecture.city_groups" :key="city_group.id">
                <h3>{{ city_group.name }}</h3>
                <dl>
                  <dd v-for="city in city_group.cities" :key="city.id">
                    {{ city.name }}({{ city.enable_count }})
                  </dd>
                </dl>
              </li>
            </ul>
          </li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
 import auth_store from '../store/auth'

 export default {
   name: 'Home',
   store: auth_store,
   data() {
     return {
       prefectures: [],
     };
   },
   created() {
     this.axios.get('/v2/master/prefecture')
       .then(response => {
         this.prefectures = response.data.prefectures;
       })
       .catch(e => {
         console.log(e.response);
         //this.$store.commit('change_access_token', null);
       });
   }
 };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 dl dd {
     display: inline;
 }
</style>
