import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    errors: null,
    access_token: null,
    thumb_image_url: null,
  },
  getters: {
    getState: (state) => () => {
      return state;
    },
    getErrorMessage(state) {
      const message = String(state.errors.message);
      return message;
    },
  },
  mutations: {
    change_errors(state, payload) {
      state.errors = payload;
    },
    clear_state(state) {
      state.access_token = null;
      state.thumb_image_url = null;
    },
    change_access_token(state, payload) {
      state.access_token = payload;
    },
    change_thumb_image_url(state, payload) {
      state.thumb_image_url = payload;
    },
  },
  plugins: [
    createPersistedState({ key: 'Store_Tochi_Auth', }),
  ],
});
