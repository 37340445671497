import Vue from 'vue'
import Vuex from 'vuex'
import VueAxios from 'vue-axios'
import GAuth from 'vue-google-oauth2'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from './router'
import axios from './http'
import { google_auth } from './oauth2'

Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(VueAxios, axios);
Vue.use(GAuth, google_auth);

const app = new Vue({
  vuetify,
  router,
  render: h => h(App),
});

app.$mount('#app');
