<template>
  <v-container ml-1 mr-1 ml-md-8 ml-lg-8 mr-md-8 mr-lg-8>
    <v-row>
      <v-col cols="12">
        <TochiSearchForm
            v-bind:store="$store"
        />
      </v-col>
      <v-col cols="12">
        <TochiList
            v-bind:items="items"
            v-bind:pagination="pagination"
            v-bind:loading="loading"
            v-bind:store="$store"
            v-on:page-change="(...args) => pageChange(...args)"
        />
        <router-link to="/hello" target="_blank">Go to home</router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
 import TochiList from './common/TochiList'
 import TochiSearchForm from './common/TochiSearchForm'
 import TochiLatstStore from '../store/tochi_latest'

 export default {
   name: 'TochiSearch',
   store: TochiLatstStore,
   components: {
     TochiSearchForm,
     TochiList,
   },
   props: [
     'id',
   ],
   data() {
     return {
       prefecture_id: null,
       items: [],
       loading: true,
       pagination: {
         page: 0,
         length: 0,
         total_count: 0,
       },
     };
   },
   mounted() {
     this.$store.subscribe(() => {
       this.fetchPage({ page: 1, });
     });
   },
   watch: {
     '$route.params.prefecture_id': {
       handler() {
         const prefecture_id = parseInt(this.$route.params.prefecture_id);
         this.$store.commit('change_prefecture_id', prefecture_id);
       },
     }
   },
   created() {
     const prefecture_id = parseInt(this.$route.params.prefecture_id);
     this.$store.commit('change_prefecture_id', prefecture_id);
   },
   methods: {
     pageChange(options) {
       this.fetchPage(options);
     },
     fetchPage(options) {
       const page = options.page;
       const prefecture_id = this.$store.getters.getPrefectureId;
       const state = this.$store.getters.getState;

       const params = [
         `prefecture_id=${prefecture_id}`,
         `page=${page}`,
         `visible=${state.search.visible}`,
       ];

       if (state.sort.key) {
         params.push(`sort_by=${state.sort.key}`);
         params.push(`sort_desc=${state.sort.desc ? 1 : 0}`);
       }

       (state.search.selected_cities || []).forEach(city => {
         params.push(`city_ids[]=${city.value}`);
       });

       (state.search.selected_platform_ids || []).forEach(platform_id => {
         params.push(`platform_ids[]=${platform_id.value}`);
       });

       if (state.search.has_history) {
         params.push(`has_history=1`);
       }

       if (state.search.cached_at.enabled) {
         params.push(`max_days_ago=${state.search.cached_at.val}`);
       }

       if (state.search.isshu_tanka.enabled) {
         params.push(`isshu_tanka_min=${state.search.isshu_tanka.range[0]}`);
         params.push(`isshu_tanka_max=${state.search.isshu_tanka.range[1]}`);
       }

       if (state.search.kakaku.enabled) {
         params.push(`kakaku_min=${state.search.kakaku.range[0]}`);
         params.push(`kakaku_max=${state.search.kakaku.range[1]}`);
       }

       if (state.search.menseki.enabled) {
         params.push(`menseki_min=${state.search.menseki.range[0]}`);
         params.push(`menseki_max=${state.search.menseki.range[1]}`);
       }

       this.items = []
       this.loading = true;

       this.axios.get(`/v2/tochi?${params.join('&')}`)
         .then(response => {
           this.items = response.data.tochi_latests;
           this.pagination.page = response.data.meta.current_page;
           this.pagination.length = response.data.meta.total_pages;
           this.pagination.total_count = response.data.meta.total_count;
           this.loading = false;
         })
         .catch((err) => {
           this.msg = 'Error:' + err;
           this.loading = false;
         });
     },
   },
 };
</script>
