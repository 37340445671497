import Vue from 'vue'
import Router from 'vue-router'
import Home from './components/Home'
import TochiSearch from './components/TochiSearch'
import HelloWorld from './components/HelloWorld'

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { title: '土地マスター', },
    },
    {
      path: '/prefecture/:prefecture_id',
      name: 'prefecture',
      component: TochiSearch,
    },
    {
      path: '/hello',
      name: 'hello',
      component: HelloWorld,
    },
  ]
});
