import axios from 'axios'
import Constant from './constant.js'
import auth_store from './store/auth'

const axios_instance = axios.create({
  baseURL: Constant.API_HOST,
  timeout: 10000,
  headers: { 'x-ua': 'tochi-frontend' },
  withCredentials: true,
});

axios_instance.interceptors.response.use(response => { return response }, error => {
  if (error.response?.status === 401) {
    auth_store.commit('clear_state');
    location.href = '/';
    return;
  }

  return Promise.reject(error);
});

export default axios_instance;
